<template>
    <div>
        <h2 class="body-semibold-18">{{ category?.name }}</h2>
        <Divider />
        <div class="sub_area_list">
            <div v-for="(area, key) in data" :key="key" v-if="area?.subjects.length > 0">
                <div class="sub_area_item">
                    <h3 class="body-semibold-16 sub_area_title">
                        {{ area.name }}
                    </h3>
                    <div class="buttons_area_list">
                        <router-link v-for="(item, index) in area?.subjects" :key="index"
                            :to="`/exercicios/${category?.url}/${item?.url}`">
                            <ButtonPlay size="medium" fullWidth @click="setSubCategory(item)">
                                {{ item.name }}
                            </ButtonPlay>
                        </router-link>
                    </div>
                </div>
                <Divider />
            </div>

        </div>
    </div>
</template>

<script>
import ButtonPlay from '../../common/ButtonPlay.vue';
import Divider from '../../common/Divider.vue';
import { mapActions } from "vuex";

export default {
    components: {
        Divider,
        ButtonPlay,
    },
    props: {
        data: {
            type: Array,
            default: () => null,
        },
        loading: {
            type: Boolean,
            default: true,
        },
        category: {
            type: Object,
            default: () => null,
        }
    },
    methods: {
        ...mapActions({
            actionSetSubCategory: "exercises/actionSetSubCategory",
        }),
        setSubCategory(item) {
            this.actionSetSubCategory(item);
        },

    },
};
</script>

<style lang="scss" scoped>
h2 {
    color: var(--fontcolor)
}

.buttons_area_list {
    @include flex-column;
    gap: 0.5rem;
    flex-grow: 1;
}

.sub_area_list {
    @include flex-column;
}

.sub_area_title {
    width: 24rem;
    color: var(--fontcolor);

    @media screen and (max-width: 769px) {
        width: 100%;
    }
}

.sub_area_item {
    @include flex-row;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 100%;

    @media screen and (max-width: 769px) {
        @include flex-column;
    }
}
</style>